import React, { useEffect, useState } from 'react';
import { GetApi } from '../Api/Dropdown';
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Button, IconButton, Menu, MenuItem ,Fade} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../Api/Endpoints';
import {useToken} from '../store';
import CustomStyles from '../CustomStyles';


function FinalProdList() {
    const [data, setData] = useState([]);
    const [showImg, setShowImg] = useState(false);
    const [show, setShow] = useState(false);
    const token =  sessionStorage.getItem("token")
    const nav = useNavigate();
    const LinesComponent = ({ data }) => {
      
        const subColumns = [
        
          {
            name: "Component ID",
            selector: (row) => row.componentId,
            sortable: true,
            minWidth: "130px",
          },
          {
            name: "Component Code",
            selector: (row) => row.componentCode,
            sortable: true,
            minWidth: "130px",
          },
          {
            name: "Quantity",
            selector: (row) => row.quantity,
            sortable: true,
            minWidth: "130px",
          },
         
        ];
        return (
          <>
            <DataTable
              columns={subColumns}
              data={data.componentDetails}
              pagination
              dense
              selectableRowsHighlight="true"
              compact
              highlightOnHover="true"
              customStyles={CustomStyles}
            />
           
          </>
        );
      };
    // Fetch product data from API
    const fetchProducts = async () => {
        try {
            const response = await GetApi("masters/finalProductList",token);
            setData(response.data.primary);
            console.log(response.data.primary);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };
    // ActionMenu component to handle actions for each row

    // Columns configuration for DataTable
    const Columns = [
       
        {
            name: "Product ID",
            selector: (row) => row.productId,
            sortable: true,
            minWidth: '100px',
        },
        {
            name: "Product Name",
            selector: (row) => row.productName,
            sortable: true,
            minWidth: '100px',
        },
        {
            name: "Product Code",
            selector: (row) => row.productCode,
            sortable: true,
            minWidth: '100px',
        },
        {
            name: "Product Description",
            selector: (row) => row.productDescription,
            sortable: true,
            minWidth: '200px',
        },
        {
            name: "Category",
            selector: (row) => row.category,
            sortable: true,
            minWidth: '100px',
        },
        
    ];
    // Fetch products on component mount
    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div>
            <DataTable
                columns={Columns}
                data={data}
                pagination
                dense
                selectableRowsHighlight={true}
                compact
                highlightOnHover={true}
                customStyles={CustomStyles}
                expandableRows
                expandableRowsComponent={LinesComponent}
            />

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>QR Code: ProductID - {showImg.productId}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <center>
                        <img src={showImg.qrCode} style={{ width: "60%" }} alt="QR Code"></img>
                    </center>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default FinalProdList;
