import axios from "axios";
import {useToken} from "../store";
import Swal from "sweetalert2";
import { BaseUrl } from "./Endpoints";



export const getSuppliers = async (id,) => {
  let api = `${BaseUrl}/masters/supplierDropdown?componentCode=${id}`;
  try {
    const response = await axios.get(api);
    console.log(response);
    return response.data.primary || [];
  } catch (err) {
    console.error("Error fetching site list:", err);
    return [];
  }
};

// Post Api

export const PostApi = async (api, payload, token) => {
  console.log("calling..............");
  console.log(api,payload,token)
 
  try {
    let response = await axios.post(BaseUrl+api, payload, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
    console.log("Post Api ", response);
  
    return response;
  } catch (err) {
    console.error("Error during Post Api:", err);
    return [];
  }
};



export const GetApi = async (api, token) => {
  try {
    let response = await axios.get(BaseUrl + api, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return [];
  }
};
