const trackingInfo = {
    name: "Laptop: XPS-15", // Main laptop
    children: [
      {
        name: "Processor ID: PROC-001", // Subpart 1
        children: [
          {
            name: "Storage: CPU Warehouse",
            children: [
              {
                name: "Processing: Chipset Assembly",
                children: [
                  {
                    name: "Supplier: Intel Suppliers",
                    children: [
                      {
                        name: "Factory: Semiconductor Plant - Arizona Fab",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Battery ID: BATT-002", // Subpart 2
        children: [
          {
            name: "Storage: Battery Warehouse",
            children: [
              {
                name: "Processing: Battery Assembly",
                children: [
                  {
                    name: "Cell Supplier: Lithium Ion Suppliers",
                    children: [
                      {
                        name: "Factory: Battery Cell Plant - Nevada Energy Storage",
                      },
                    ],
                  },
                  {
                    name: "BMS Supplier: Battery Management System Suppliers",
                    children: [
                      {
                        name: "Factory: BMS Manufacturing - California Controls",
                      },
                    ],
                  },
                  {
                    name: "Casing Supplier: Battery Casing Suppliers",
                    children: [
                      {
                        name: "Factory: Battery Casing Factory - Illinois Metal Works",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Display ID: DISP-003", // Subpart 3
        children: [
          {
            name: "Storage: Display Warehouse",
            children: [
              {
                name: "Processing: LCD Panel Assembly",
                children: [
                  {
                    name: "Supplier: Display Panel Suppliers",
                    children: [
                      {
                        name: "Factory: Display Factory - South Korea Visuals Co.",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
  
  export default trackingInfo;
  