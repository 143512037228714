import React, { useEffect, useState } from "react";
import { GetApi } from "../../Api/Dropdown";
import CustomStyles from "../../CustomStyles";
import DataTable from "react-data-table-component";

function ServiceHistory() {
  const token = sessionStorage.getItem("token");
  const [data,setData]=useState([])
  const fetchData = async () => {
    try {
      const response = await GetApi("finalProduct/serviceHistoryList", token);
      console.log(response);
      setData(response.data.primary)
    } catch (error) {
      console.error("Failed to fetch component info:", error);
    }
  };
  const columns = [
    {
      name: "Parent Component ID",
      selector: (row) => row.parentComponentId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Product ID",
      selector: (row) => row.productId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Component ID",
      selector: (row) => row.componentId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Component Name",
      selector: (row) => row.componentName,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Component Code",
      selector: (row) => row.componentCode,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Request ID",
      selector: (row) => row.serviceRequestId,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Description",
      selector: (row) => row.serviceDescription,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Expected Completion Date",
      selector: (row) => row.expectedCompletionDate || "N/A",
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Request Date",
      selector: (row) => new Date(row.requestDate).toLocaleDateString(),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Requested By",
      selector: (row) => row.requestedBy,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Service Provider Type",
      selector: (row) => row.serviceProviderType,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Status",
      selector: (row) => row.serviceStatus,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Service Provider ID",
      selector: (row) => row.serviceProviderId,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Provider Name",
      selector: (row) => row.serviceProviderName,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Date & Time",
      selector: (row) => row.dateTime,
      sortable: true,
      minWidth: "200px",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="planning">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">Service</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
        <DataTable
      title="Service Requests"
      columns={columns}
      data={data}
      pagination
      selectableRowsHighlight={true}
        compact
        highlightOnHover={true}
 
        customStyles={CustomStyles}
    />
        </div>
      </div>
    </div>
  );
}

export default ServiceHistory;
